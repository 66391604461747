.daterangepicker {
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #12588a;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #4897cf;
}

.daterangepicker td.in-range {
  background-color: #dcf0ff;
}

.btn-primary {
  background-color: #12588a;
  border-color: #12588a;
}

div[role="tooltip"] {
  z-index: 1400;
}
